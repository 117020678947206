<template>
  <div class="preview">
    <img v-if="path" :src="getImageURL" :alt="alt">
    <img v-else src="@/assets/logo-arene.png" :alt="alt">
  </div>
</template>

<script>
import staticFileMixin from '@/mixins/static-file-mixin';

export default {
  name: 'ImageItem',
  props: {
    path: {
      type: String,
      required: false,
    },
    alt: {
      type: String,
      required: false,
    },
  },
  computed: {
    getImageURL() {
      if (this.path && this.path.startsWith('blob')) {
        return this.path;
      }
      return this.getFile(this.path);
    },
  },
  mixins: [staticFileMixin],
};
</script>

<style scoped>
.preview img {
  width: auto;
  max-height: 250px;
  display: block;
}
</style>
