<template>
  <div>
    <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false"
               :md-active.sync="isModalVisible">

      <md-dialog-content class="modal">
        <validator v-slot="{ handleSubmit }">
          <form novalidate @submit.prevent="handleSubmit(onSubmit)">

            <span class="md-headline">{{ title }}</span>

            <div class="md-layout md-alignment-center">
              <image-preview :path="object.image_path" alt="image of the game"/>
            </div>

            <rule name="nom" rules="required" v-slot="{errors}">
              <md-field>
                <label>Nom</label>
                <md-input name="name" v-model="object.name"/>
                <span class="input-error">{{ errors[0] }}</span>
              </md-field>
            </rule>

            <md-field>
              <label>Image</label>
              <md-file type="file" accept="image/*" @change="addImage" :value="object.image_path"/>
              <div>
                <md-button class="md-icon-button md-accent md-dense" @click="removeImage">
                  <md-icon>delete</md-icon>
                </md-button>
                <md-tooltip md-direction="top">effacer l'image</md-tooltip>
              </div>
            </md-field>

            <div class="buttons-panel">
              <md-button class="md-raised md-accent" @click="close">Annuler</md-button>
              <md-button type="submit" class="md-raised md-primary">Valider</md-button>
            </div>
          </form>
        </validator>
      </md-dialog-content>
    </md-dialog>
    <div class="fab-space"/>
  </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/fr.json';
import formMixin from '@/mixins/form-mixin';
import GameService from '@/components/game/GameService';
import staticFileMixin from '@/mixins/static-file-mixin';
import ImagePreview from '@/components/shared/image-preview/ImagePreview.vue';

extend('required', {
  ...required,
  message: messages.required,
});
export default {
  name: 'GameForm',
  data: () => ({
    model: 'Jeu',
    service: GameService,
  }),
  methods: {
    addImage(event) {
      const [image] = event.target.files;
      const object = { ...this.object, image, image_path: URL.createObjectURL(image) };
      this.$store.dispatch('setObject', object);
    },
    removeImage() {
      const object = { ...this.object, image: null, image_path: '' };
      this.$store.dispatch('setObject', object);
    },
  },
  mixins: [staticFileMixin, formMixin],
  components: {
    ImagePreview,
    rule: ValidationProvider,
    validator: ValidationObserver,
  },
};
</script>

<style>

.modal {
  max-width: 500px;
}
</style>
