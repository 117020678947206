<template>
  <div>
    <div class="lateral-padding md-layout md-alignment-center">
      <h1 class="md-layout-item">{{ title }}</h1>
      <search-bar class="md-layout-item" @input.native="filter = $event.target.value"/>
    </div>

    <md-list-item class="toolbar md-layout md-subheading">
      <div class="md-layout md-alignment-center md-xsmall-size-85">
        <div class="md-layout-item md-small-hide"></div>
        <div class="md-layout-item md-xsmall-size-100"><span>Nom</span></div>
        <div class="md-layout-item md-layout-item md-size-10 md-small-20 md-xsmall-size-15"></div>
      </div>
    </md-list-item>
    <md-list>
      <game-item v-for="game of filteredGames" :key="game.id" :object="game"
                 @deleted="removeObject"/>
    </md-list>

    <game-form @submit="saveObject"/>
  </div>
</template>

<script>

import GameItem from '@/components/game/GameItem.vue';
import GameForm from '@/components/game/GameForm.vue';
import GameService from '@/components/game/GameService';
import SearchBar from '@/components/shared/search-bar/SearchBar.vue';
import notificationMixin from '@/mixins/notification-mixin';
import pageMixin from '@/mixins/page-mixin';

export default {
  name: 'GamesPage',
  data: () => ({
    title: 'Jeux',
    filter: '',
  }),
  components: {
    SearchBar,
    GameItem,
    GameForm,
  },
  mixins: [pageMixin, notificationMixin],
  computed: {
    filteredGames() {
      if (this.filter) {
        const exp = new RegExp(this.filter.trim(), 'i');
        return this.objects.filter((game) => exp.test(game.name));
      }
      return this.objects;
    },
  },
  async mounted() {
    try {
      this.objects = await GameService.index();
    } catch (err) {
      this.handleError(err);
    }
  },
};
</script>
