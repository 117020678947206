<template>
  <div>
    <md-list-item class="md-layout md-alignment-center">

      <div class="md-layout-item md-xsmall-size-100 md-small-hide">
        <image-item :path="object.image_path" alt="image of the game"/>
      </div>

      <div class="md-layout-item md-xsmall-size-50 md-list-item-text">
        <span>{{ object.name }}</span>
      </div>

      <div class="md-layout md-layout-item md-size-10 md-small-20 md-xsmall-size-15">
        <div class="btn-edit">
          <md-button @click="onEdit" class="md-icon-button md-dense md-raised md-accent btn-green">
            <md-icon>mode</md-icon>
          </md-button>
        </div>
        <md-button @click="active = true" class="md-icon-button md-dense md-raised md-accent">
          <md-icon>delete</md-icon>
        </md-button>
      </div>

    </md-list-item>

    <md-dialog-confirm
      :md-active.sync="active"
      md-title="Supprimer jeu"
      md-content="Vous voulez vraiment supprimer ce jeu?"
      md-confirm-text="Confirmer"
      md-cancel-text="Annuler"
      @md-confirm="onConfirm"/>

    <md-divider class="md-full"/>
  </div>
</template>

<script>

import GameService from '@/components/game/GameService';
import itemMixin from '@/mixins/item-mixin';
import ImageItem from '@/components/shared/image-item/ImageItem.vue';

export default {
  name: 'GameItem',
  data: () => ({
    service: GameService,
  }),
  components: {
    ImageItem,
  },
  mixins: [itemMixin],
};

</script>
